import React, {useEffect, useState} from "react"
import { FaChevronCircleUp } from 'react-icons/fa'
import PropTypes from "prop-types"

import "../../css/components/buttons.scss"

const ScrollToTop = props => {
    const myWindow = typeof window !== 'undefined' && window;

    const [visible, setVisible] = useState(false)

    const handleScroll = () => {
        if(myWindow.pageYOffset <= 50)
            setVisible(false)
        else
            setVisible(true)
    }

    useEffect(() => {
        myWindow.addEventListener('scroll', handleScroll)
        return (() => {
            myWindow.removeEventListener('scroll', handleScroll)
        })
    })

    return <div className={"scrollToTop" + (!visible ? " hide" : "")} onClick={props.scrollToTop}><FaChevronCircleUp/></div>
}

ScrollToTop.propTypes = {
    scrollToTop: PropTypes.func.isRequired,
}

export default ScrollToTop
