import React from "react"
import PropTypes from "prop-types";

const Section = ({id, title, description, children}) => {
    return (
        <section id={id}>
            <h2 className={"title"}>{title}</h2>
            <p className={"description"}>{description}</p>

            {children}
        </section>
    )
}

Section.defaultProps = {
    id: '',
    title: '',
    content: '',
}

Section.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.object,
    content: PropTypes.string,
}

export default Section
