import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import ScrollToTop from "../Buttons/scrollToTop"

const Footer = ({scrollToTop}) => (
    <footer>
        <div className={"row"}>
            <div className={"col-12 col-md-4"}>
                <div><h3>Artisans couvreurs, maçons, menuisiers à Laon</h3></div>
            </div>
            <div className={"copyright col-12 col-md-4"}>
                Développé par {` `} <a href="https://www.srdigitale.fr">l'agence web SR Digitale</a>
            </div>
            <div className={"col-12 col-md-4"}>
                <div><Link to="/mentions-legales">Mentions légales</Link></div>
            </div>
        </div>


        <ScrollToTop scrollToTop={scrollToTop}/>
    </footer>
)

Footer.propTypes = {
    scrollToTop: PropTypes.func.isRequired,
}

export default Footer
