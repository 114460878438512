import React from "react"
import CookieConsent from "react-cookie-consent"

import "../../css/components/cookie.scss"
import {Link} from "gatsby";

const Cookie = () => {
    const onAccept = () => {

    }

    const onDecline = () => {

    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            declineButtonText={"Je refuse"}
            cookieName="athies-batiment-rgpd"
            containerClasses={"cookie-rpgd"}
            contentClasses={"cookie-rgpd-content"}
            buttonClasses={"cookie-rgpd-button"}
            declineButtonClasses={"cookie-rgpd-declineButton"}
            /*style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "1em" }}*/
            enableDeclineButton={true}
            flipButtons={true}
            expires={150}
            onAccept={onAccept}
            onDecline={onDecline}
        >
            Nous utilisons des cookies afin de vous proposer la meilleure expérience possible. Vous pouvez à tout moment les supprimer, pour cela, rendez-vous sur cette page : <Link to={"/politique-de-confidentialite"}>cliquez-ici</Link>.
        </CookieConsent>
    )
}

export default Cookie
