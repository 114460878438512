import React from "react"
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa'

import "../../css/containers/index/contact.scss"
import Section from "../../components/section"
import ContactForm from "./contactForm"

const Contact = () => {
    return (
        <Section
            id="contact"
            title="Contactez-nous"
            description={<p>Vous pouvez nous envoyer un message grâce au formulaire ci-dessous ou venir nous rencontrer directement dans nos bureaux à Laon afin que l'on puisse échanger sur vos projets de travaux de maçonnerie.</p>}>
            <div className={"row"}>
                    <div className={"form-contact col-12 col-lg-6"}>
                        <div className={"text"}>
                            <span className={"text-sm"}>Vous pouvez aussi nous contacter grâce aux informations sous le formulaire.</span>
                        </div>
                        <ContactForm/>
                    </div>
                    <div className={"infos-contact col-12 col-lg-6"}>
                        <div className={"row row-contact"}>
                            <div className={"col-contact col-12 col-md-6"}>
                                <FaPhone className={"icon"}/>
                                <div className={"title"}>Téléphone</div>
                                <a className={"information"} href={"tel:+33646168723"}>06.46.16.87.23</a>
                                <a href={"tel:+33646168723"} className={"cta"}>Appeler</a>
                            </div>
                            <div className={"col-contact col-12 col-md-6"}>
                                <FaEnvelope className={"icon"}/>
                                <div className={"title"}>Mail</div>
                                <a className={"information"} href={"mailto:jeanbaptiste.prudhomme@sfr.fr"}>jeanbaptiste.prudhomme@sfr.fr</a>
                                <a href={"mailto:jeanbaptiste.prudhomme@sfr.fr"} className={"cta"}>Envoyer un email</a>
                            </div>
                        </div>
                        {/*<Img className={"map"} fluid={data.image.childImageSharp.fluid} />*/}
                        <div className={"row row-contact"}>
                            <div className={"col-contact col-12 col-md-6"}>
                                <FaClock className={"icon"}/>
                                <div className={"title"}>Horaires</div>
                                <div className={"information"}>Du lundi au vendredi - de 9h00 à 18h00</div>
                                <a href={"https://www.google.com/maps/dir//76+Rue+du+Ch%C3%A2teau+d'Eau,+02840+Athies-sous-Laon/@49.5732595,3.6594988,12.75z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47e9b460e064ca53:0x5d8965db988b7b3c!2m2!1d3.6930546!2d49.5685374!3e0"} className={"cta"}>Nous rencontrer</a>
                            </div>
                            <div className={"col-contact col-12 col-md-6 address"}>
                                <FaMapMarkerAlt className={"icon"}/>
                                <div className={"title"}>Adresse</div>
                                <a href={"https://www.google.com/maps/dir//76+Rue+du+Ch%C3%A2teau+d'Eau,+02840+Athies-sous-Laon/@49.5732595,3.6594988,12.75z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47e9b460e064ca53:0x5d8965db988b7b3c!2m2!1d3.6930546!2d49.5685374!3e0"}
                                   className={"information"}>76 Rue du Château d'Eau, 02840 Athies-sous-Laon, France</a>
                                <a href={"https://www.google.com/maps/dir//76+Rue+du+Ch%C3%A2teau+d'Eau,+02840+Athies-sous-Laon/@49.5732595,3.6594988,12.75z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47e9b460e064ca53:0x5d8965db988b7b3c!2m2!1d3.6930546!2d49.5685374!3e0"} className={"cta"}>Voir l'itinéraire</a>
                            </div>
                        </div>
                    </div>
                </div>
        </Section>
    )
}

export default Contact
