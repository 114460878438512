import emailjs from 'emailjs-com';

export const sendEmail = (params) => {
    return new Promise((resolve, reject) => {
        const parameters = {
            "name": params.name,
            "email": params.email,
            "phone": params.phone,
            "subject": params.subject,
            "message": params.message
        };

        emailjs.send('default_service', 'ab_contact', parameters, 'user_vxUz2Z0w6HQQeZZ5L1F8e')
            .then((result) => {
                resolve(result.text)
            }, (error) => {
                reject(error.text)
            });
    })
};
