import React, {useState, useEffect} from "react"
import ReactLoading from "react-loading"

import {sendEmail} from "../../utils/email"
import {success, error} from "../../utils/alert"

const ContactForm = () => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        rgpd: false,
    })

    const [loading, setLoading] = useState(false)
    const [canSend, setCanSend] = useState(false)

    const onChange = e => {
        const name  = e.target.name;
        const value = name === "rgpd" ? e.target.checked : e.target.value;

        setForm(prevForm => {
            return {
                ...prevForm,
                [name]: value
            }})
    };

    useEffect(() => {
        if(form.name !== '' && form.email !== '' && form.subject !== '' && form.message !== '' && form.rgpd === true)
            setCanSend(true)
        else
            setCanSend(false)
    }, [form])

    const onSubmit = e => {
        e.preventDefault()

        setLoading(true)

        sendEmail(form)
            .then(res => {
                setLoading(false)
                success()
            })
            .catch(res => {
                setLoading(false)
                error()
            })
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <input type={"text"} placeholder={"Nom et prénom *"} name={"name"} value={form.name} onChange={onChange}/>
            </div>
            <div className="form-group">
                <input type={"email"} placeholder={"Adresse mail *"} name={"email"} value={form.email} onChange={onChange}/>
            </div>
            <div className="form-group">
                <input type={"text"} placeholder={"N° téléphone"} name={"phone"} value={form.phone} onChange={onChange}/>
            </div>
            <div className="form-group">
                <input type={"text"} placeholder={"Sujet *"} name={"subject"} value={form.subject} onChange={onChange}/>
            </div>
            <div className="form-group">
                <textarea rows={5} placeholder={"Message *"} name={"message"} value={form.message} onChange={onChange}/>
            </div>
            <div className="form-group">
                <div className={"row"}>
                    <div className={"col-12 col-lg-2"}>
                        <label className="switch">
                            <input id={"rgpd-accept"} type={"checkbox"} name={"rgpd"} value={form.rgpd} onChange={onChange}/>
                            <span className="slider round"/>
                        </label>
                    </div>
                    <div className={"col-12  col-lg-10"}>
                        <label htmlFor={"rgpd-accept"} className={"label-checkbox"}>
                            En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de contact et de la relation commerciale qui peut en découler.
                        </label>
                    </div>
                </div>
            </div>

            <button type={"submit"} className={"btn btn-primary sr-button btn-yellow"} disabled={!canSend}>
                {loading ? <ReactLoading type={"spin"} color={"#ffffff"} height={25} width={25} className={"sr-loading"} /> : "Envoyer"}
            </button>
        </form>
    )
}

export default ContactForm
