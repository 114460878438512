import Swal from "sweetalert2";

export const success = (message = "Votre message a bien été envoyé", title = null) => {
    Swal.fire({
        title: title,
        icon: 'success',
        text: message,
    });
};


export const error = (message = "Votre message n'a pas pu être envoyé", title = null) => {
    Swal.fire({
        title: title,
        icon: 'error',
        text: message,
    })
};
