import React, {useState, useEffect} from "react"
import { Navbar, Nav } from 'react-bootstrap'
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"


const Menu = () => {
    const data = useStaticQuery(graphql`
        query {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid
              }
            }
          },
        }
   `)

    const myWindow = typeof window !== 'undefined' && window;

    const [pos, setPos] = useState(myWindow.pageYOffset)
    const [visible, setVisible] = useState(true)

    const handleScroll = () => {
        let temp = myWindow.pageYOffset

        setVisible(pos > temp);
        setPos(temp)
    }

    useEffect(() => {
        if(pos === 0)
            setVisible(true)

        myWindow.addEventListener('scroll', handleScroll)
        return (() => {
            myWindow.removeEventListener('scroll', handleScroll)
        })
    })

    return (
        <Navbar collapseOnSelect expand="lg" bg="white" className={"menu fixed-top" + (!visible ? ' hide' : '')}>
            <Navbar.Brand href="/">
                <Img fluid={data.logo.childImageSharp.fluid} alt={"Logo de l'entreprise Athies Batiment dans l'Aisne"} className={"logo"}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link href="/#home">
                        Accueil
                    </Nav.Link>
                    <Nav.Link href="/#services">
                        Services
                    </Nav.Link>
                    <Nav.Link href="/#certifications">
                        Certifications
                    </Nav.Link>
                    <Nav.Link href="/devis" className={"quotation"}>Devis gratuit</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Menu
