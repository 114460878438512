/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import 'bootstrap/dist/css/bootstrap.min.css'
import * as Scroll from 'react-scroll'
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import "../../css/components/layout.css"
import "../../css/main.scss"
import Menu from "./menu"
import Footer from "./footer"
import Cookie from "./cookie"

class Layout extends Component {
    componentDidMount() {
        Events.scrollEvent.register('begin', function(to, element) {
            //console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function(to, element) {
            //console.log("end", arguments);
        });

        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render() {
        return (
            <>
                <Menu/>
                <div className={"layout"}>
                    <main>{this.props.children}</main>
                    <Footer scrollToTop={this.scrollToTop}/>
                </div>
                <Cookie/>
            </>
        )
    }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
